/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react';
import { Dropdown } from 'react-bootstrap';
import SVG from '../../assets/images/images';
import { loginWithMetaMask } from '../../web3';
import { toast } from 'react-toastify';
import { shortWalletAddress } from '../../utils/helpers';
import {
  CHAINS_BY_NETWORK_NAME,
  CHAIN_NAME_BY_CHAIN_ID,
  NETWORKS,
  NETWORKS_BY_NAME,
} from '../../utils/constants';

import WrapContext from '../../context/WrapContext';

const NetworkDropdown = ({
  web3Instance,
  connectedWallet,
  setConnectedWallet,
}) => {
  const { from, setFrom } = useContext(WrapContext);

  const [network, setNetwork] = useState('');
  console.log(
    'file: NetworkDropdown.jsx:27 ~ NetworkDropdown ~ network:',
    network
  );

  useEffect(() => {
    if (network?.id) {
      handleNetworkSwitch();
    }
  }, [network?.id]);

  useEffect(() => {
    const getCurrentSelectedNetwork = async () => {
      try {
        if (web3Instance) {
          const chainId = await web3Instance.eth.getChainId();
          setNetwork(NETWORKS_BY_NAME[CHAIN_NAME_BY_CHAIN_ID[chainId]]);
          const connectedWalletResponse = await loginWithMetaMask(
            CHAIN_NAME_BY_CHAIN_ID[chainId]
          );
          setFrom(CHAINS_BY_NETWORK_NAME[CHAIN_NAME_BY_CHAIN_ID[chainId]]);

          setConnectedWallet(connectedWalletResponse);
        }
      } catch (err) {
        console.error('getCurrentSelectedNetwork ~ err:', err);
        toast.error(err?.message);
      }
    };
    getCurrentSelectedNetwork();
  }, [from?.name]);

  const handleNetworkSwitch = async () => {
    try {
      const connectedWalletResponse = await loginWithMetaMask(network?.id);
      setFrom(CHAINS_BY_NETWORK_NAME[network?.id]);
      setConnectedWallet(connectedWalletResponse);
    } catch (err) {
      console.error(
        'file: NetworkDropdown.jsx:31 ~ handleNetworkSwitch ~ err:',
        err
      );
      toast.error(err.message);
      setNetwork('');
    }
  };

  return (
    <div className='d-flex align-items-center'>
      <Dropdown align='end'>
        <Dropdown.Toggle id='dropdown-basic'>
          {connectedWallet?.network?.logo
            ? connectedWallet?.network?.logo
            : 'Select Network'}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {NETWORKS.map((item) => (
            <Dropdown.Item
              key={item.id}
              as='button'
              onClick={() => setNetwork(item)}
            >
              <span className='svg'>{item?.logo}</span>
              <div className='name'>{item?.label}</div>
              {item?.id === network?.id && (
                <div className='check'>
                  <SVG.Check />
                </div>
              )}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      <button className='btn-header ms-3' onClick={handleNetworkSwitch}>
        {connectedWallet?.walletAddress
          ? shortWalletAddress(connectedWallet?.walletAddress)
          : 'Connect'}
      </button>
    </div>
  );
};

export default NetworkDropdown;
