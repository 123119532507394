// TODO: Ethereum contract function
import { getWeb3 } from '../index';
import { getContract } from './CommonContractFunctions';
import { CHAIN_NAME_BY_CHAIN_ID } from '../../utils/constants';
import { addTransaction } from '../../server';
import { toast } from 'react-toastify';

export const burnWrapStor = async (amountToLockInTreasure, otherChainName) => {
  console.log('burnWrapStor ~ amountToLockInTreasure:', amountToLockInTreasure);
  try {
    const web3 = await getWeb3();
    const accounts = await web3.eth.getAccounts();

    const chainId = await web3.eth.getChainId();

    const contract = await getContract(web3);
    const amountToLockInTreasureWei = web3.utils.toWei(
      amountToLockInTreasure?.toString()
    );

    let optionalObject = {};

    if (chainId === 137) {
      const gasPrice = await web3.eth.getGasPrice();
      const gas = await contract.methods
        .burn(amountToLockInTreasureWei)
        .estimateGas({
          from: accounts[0],
        });

      optionalObject = {
        gasPrice: parseInt(gasPrice),
        gas,
      };
      console.log('burnWrapStor ~ optionalObject:', optionalObject);
    }

    await contract.methods
      .burn(amountToLockInTreasureWei)
      .send({
        from: accounts[0],
        ...optionalObject,
      })
      .on('transactionHash', async (hash) => {
        toast.success(
          'The transaction is currently being confirmed by the blockchain. Once confirmed, we will proceed to process your request.'
        );
        const payload = {
          txnHashWStor: hash,
          walletAddress: accounts[0],
          fromBlockchain: CHAIN_NAME_BY_CHAIN_ID[chainId],
          toBlockchain: otherChainName,
          conversionType: 'WSTOR to STOR',
        };
        console.log('.on ~ payload:', payload);

        const addTransactionResponse = await addTransaction(payload);
        if (addTransactionResponse?.success) {
          toast.success(addTransactionResponse?.message);
        }
        // return payload;
      })
      .on('receipt', (_receipt) => {
        toast.success(
          'The transaction has been confirmed by the blockchain. We are now processing your request, and you can expect to receive your tokens in your wallet shortly.'
        );
      });

    // return depositInTreasuryResponse;
  } catch (err) {
    console.error(
      'file: ethChainContract.js:7 ~ depositInTreasury ~ err:',
      err
    );
    throw err;
  }
};
