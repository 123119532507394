import { toast } from 'react-toastify';
import { getWeb3 } from '../index';
import { getContract } from './CommonContractFunctions';
import { CHAIN_NAME_BY_CHAIN_ID } from '../../utils/constants';
import { addTransaction } from '../../server';

export const transferIntoTreasury = async (
  amountTransferIntoTreasury,
  otherChainName
) => {
  try {
    const web3 = await getWeb3();
    const accounts = await web3.eth.getAccounts();
    const chainId = await web3.eth.getChainId();

    const contract = await getContract(web3);
    const amountTransferIntoTreasuryWei = web3.utils.toWei(
      amountTransferIntoTreasury.toString()
    );

    await contract.methods
      .transferIntoTreasury()
      .send({ from: accounts[0], value: amountTransferIntoTreasuryWei })
      .on('transactionHash', async (hash) => {
        toast.success(
          'The transaction is currently being confirmed by the blockchain. Once confirmed, we will proceed to process your request.'
        );
        const payload = {
          txnHashStor: hash,
          walletAddress: accounts[0],
          fromBlockchain: CHAIN_NAME_BY_CHAIN_ID[chainId],
          toBlockchain: otherChainName,
          conversionType: 'STOR to WSTOR',
        };
        console.log('.on ~ payload:', payload);

        const addTransactionResponse = await addTransaction(payload);
        if (addTransactionResponse?.success) {
          toast.success(addTransactionResponse?.message);
        }
      })
      .on('receipt', (_receipt) => {
        toast.success(
          'The transaction has been confirmed by the blockchain. We are now processing your request, and you can expect to receive your tokens in your wallet shortly.'
        );
      });
  } catch (err) {
    console.error(
      'file: storageChainContract.js:34 ~ transferIntoTreasury ~ err:',
      err
    );
    throw err;
  }
};
