/* eslint-disable import/no-anonymous-default-export */
import { ReactComponent as Arrow } from './arrow.svg';
import { ReactComponent as Chev } from './chev.svg';
import { ReactComponent as Setting } from './setting.svg';
import { ReactComponent as Ethereum } from './eth.svg';
import { ReactComponent as Polygon } from './polygon.svg';
import { ReactComponent as Check } from './check.svg';
import { ReactComponent as Coin } from './coin.svg';
import { ReactComponent as FeeIcon } from './fee.svg';
// import { ReactComponent as AvalancheIcon } from './avalanche-logo.svg';
import { ReactComponent as ArbitrumIcon } from './arbitrum-arb-logo.svg';

import EthImage from './eth-img.png';
// import Avalanche from './avalanche-logo.svg';
import Arbitrum from './arbitrum-arb-logo.svg';
import PolygonImage from './polygon.png';
import STORCoin from './coin.svg';
import icon from './icon.png';
import WhiteLogo from './white-logo.svg';
import BucketImg from './bucket_image.png';

export const Images = {
  EthImage,
  PolygonImage,
  STORCoin,
  // Avalanche,
  Arbitrum,
};

export default {
  Arrow,
  Chev,
  Setting,
  Ethereum,
  Check,
  Polygon,
  Coin,
  FeeIcon,
  icon,
  WhiteLogo,
  BucketImg,
  // AvalancheIcon,
  ArbitrumIcon,
};
