import GoerliContractABI from '../ABIs/GoerliContractABI.json';
import StorageChainContractABI from '../ABIs/StorageChainContractABI.json';

export const CONTRACT_DETAILS = {
  80001: {
    abi: GoerliContractABI,
    address: process.env.REACT_APP_POLYGON_CONTRACT_ADDRESS,
  },
  80002: {
    abi: GoerliContractABI,
    address: process.env.REACT_APP_POLYGON_CONTRACT_ADDRESS,
  },
  137: {
    abi: GoerliContractABI,
    address: process.env.REACT_APP_POLYGON_CONTRACT_ADDRESS,
  },
  8726: {
    abi: StorageChainContractABI,
    // address: "0x06F94372C780952D9B2200C75Ac1e2b36B67609c",
    address: process.env.REACT_APP_STORAGECHAIN_CONTRACT_ADDRESS,
  },
  8727: {
    abi: StorageChainContractABI,
    // address: "0x06F94372C780952D9B2200C75Ac1e2b36B67609c",
    address: process.env.REACT_APP_STORAGECHAIN_CONTRACT_ADDRESS,
  },
  1998: {
    abi: StorageChainContractABI,
    // address: "0x06F94372C780952D9B2200C75Ac1e2b36B67609c",
    address: process.env.REACT_APP_STORAGECHAIN_CONTRACT_ADDRESS,
  },
  5: {
    abi: GoerliContractABI,
    address: process.env.REACT_APP_ETHEREUM_CONTRACT_ADDRESS,
  },
  1: {
    abi: GoerliContractABI,
    address: process.env.REACT_APP_ETHEREUM_CONTRACT_ADDRESS,
  },
  11155111: {
    abi: GoerliContractABI,
    address: process.env.REACT_APP_ETHEREUM_CONTRACT_ADDRESS,
  },

  // 43114: {
  //   abi: GoerliContractABI,
  //   address: process.env.REACT_APP_AVALANCHE_CONTRACT_ADDRESS,
  // },

  // 43113: {
  //   abi: GoerliContractABI,
  //   address: process.env.REACT_APP_AVALANCHE_CONTRACT_ADDRESS,
  // },

  42161: {
    abi: GoerliContractABI,
    address: process.env.REACT_APP_ARBITRUM_CONTRACT_ADDRESS,
  },

  421614: {
    abi: GoerliContractABI,
    address: process.env.REACT_APP_ARBITRUM_CONTRACT_ADDRESS,
  },
};
