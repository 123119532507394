import './App.scss';
import { useState, useEffect } from 'react';
import Dashboard from './components/Dashboard';
// import ComingSoon from './components/ComingSoon';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Socket from './utils/socket';
import SocketContext from './context/SocketContext';

function App() {
  const [socket, setSocket] = useState(undefined);
  const [connectedWallet, setConnectedWallet] = useState({
    walletAddress: '',
    balance: '',
  });

  useEffect(() => {
    if (!socket) {
      const connectedSocket = Socket.connectSocket();
      setSocket(connectedSocket);
    }
    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, []);

  return (
    <SocketContext.Provider value={socket}>
      <div className='App'>
        <ToastContainer />
        <Dashboard
          connectedWallet={connectedWallet}
          setConnectedWallet={setConnectedWallet}
        />
        {/* <ComingSoon /> */}
      </div>
    </SocketContext.Provider>
  );
}

export default App;
