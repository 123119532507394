/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react';
import { CiWarning } from 'react-icons/ci';

import SwapSection from './SwapSection';
import style from './styles.module.scss';
import SVG from '../../assets/images/images';
import FeeDetail from './FeeDetail';
import WrapContext from '../../context/WrapContext';
import { toast } from 'react-toastify';
import Spinner from 'react-bootstrap/Spinner';
import { getGasEstimations } from '../../server';
import {
  transferIntoTreasury,
  getBalanceOfEthContractByWalletAddress,
} from '../../web3/ContractIntegrations';
import { burnWrapStor } from '../../web3/ContractIntegrations/ethChainContract';
import { loginWithMetaMask, getWeb3 } from '../../web3';

import {
  CONTRACT_ADDRESSES_BY_CHAIN,
  CONVERSION_BUTTON_TEXT,
} from '../../utils/constants';
import { getCoinMetadata } from '../../web3/networksParams';

function SwapCoin({ connectedWallet, setConnectedWallet }) {
  const { from, setFrom, to, setTo } = useContext(WrapContext);
  console.log('SwapCoin ~ from:', from, to);
  const [currentTokenBalance, setCurrentTokenBalance] = useState(0);
  const [loading, setLoading] = useState(false);
  const [fetchingGasFee, setFetchingGasFee] = useState(false);
  const [feeDeductions, setFeeDeductions] = useState({
    storCoinDeductionValue: 0,
    transactionFeeInEth: 0,
    transactionFeeInUsd: 0,
    transactionFeeInWei: 0,
  });

  window.onbeforeunload = () => {
    if (loading) {
      return 'Transaction is already in progress. Are you sure you want to exit? Exiting now may result in the loss of your tokens.';
    }
  };

  const swapCoin = async () => {
    try {
      const loginResponse = await loginWithMetaMask(to?.name?.toLowerCase());
      setConnectedWallet(loginResponse);
      setFrom({ ...to, value: 0 });
      setTo({ ...from, value: 0 });
    } catch (err) {
      console.error('file: index.jsx:21 ~ swapCoin ~ err:', err);
      toast.error(err.message);
    }
  };

  const handleImportTokens = async () => {
    try {
      if (!from?.symbol) {
        throw new Error('Please select the chain first.');
      }
      const params = getCoinMetadata(
        CONTRACT_ADDRESSES_BY_CHAIN[from?.name?.toLowerCase()]
      );
      if (!params) {
        throw new Error(`WSTOR can not be imported in ${from?.name} Network`);
      }
      const customTokenAdditionRequest = await window.ethereum.request({
        method: 'wallet_watchAsset',
        params,
      });

      if (customTokenAdditionRequest) {
        toast.success('Custom Token Added.');
      }
    } catch (err) {
      toast.error(err?.message);
      console.error(
        'file: DepositInTreasury.js ~ handleImportTokens ~ err:',
        err
      );
    }
  };

  const resetState = async () => {
    setFrom({ ...from, value: 0 });
    setTo({ ...to, value: 0 });
    setLoading(false);
    await calculateNetworkFee();
  };

  const handleWrapTokens = async () => {
    try {
      if (!connectedWallet?.walletAddress) {
        throw new Error('Please connect wallet address.');
      }

      if (to?.symbol === from?.symbol) {
        throw new Error(
          `Operation between ${from?.name} (WSTOR) and ${to?.name} (WSTOR) is not supported.`
        );
      }

      if (!feeDeductions?.storCoinDeductionValue) {
        throw new Error(
          'An error occurred while retrieving gas fees. Please refresh the page and attempt the operation again.'
        );
      }

      if (!from?.value || from?.value === 0) {
        throw new Error('Please enter the value to start the process.');
      }

      // Verify Network
      const connectedWalletAndNetworkVerificationResponse =
        await loginWithMetaMask(from?.name?.toLowerCase());

      setConnectedWallet(connectedWalletAndNetworkVerificationResponse);
      setLoading(true);
      // STOR to WSTOR
      if (from?.symbol === 'STOR') {
        // TODO: Convert STOR to WSTOR
        console.log('Convert STOR to WSTOR');

        // Transfer STOR to Treasuary.
        // const transferIntoTreasuryResponse =
        await transferIntoTreasury(
          from?.value?.toString() || '0',
          to?.name?.toLowerCase()
        );
        // console.log(
        //   'file: index.jsx:50 ~ handleWrapTokens ~ transferIntoTreasuryResponse:',
        //   transferIntoTreasuryResponse
        // );
        // if (!transferIntoTreasuryResponse) {
        //   setLoading(false);
        // }
        // if (
        //   transferIntoTreasuryResponse?.status &&
        //   transferIntoTreasuryResponse?.transactionHash
        // ) {
        //   toast.success(
        //     'The transaction has been confirmed by the blockchain. We are now processing your request, and you can expect to receive your tokens in your wallet shortly.'
        //   );
        // }
      }

      // WSTOR to STOR
      if (from?.symbol === 'WSTOR') {
        // TODO: Convert WSTOR to STOR
        console.log('Convert WSTOR to STOR');
        await burnWrapStor(from?.value || '0', to.name?.toLowerCase());
      }
    } catch (err) {
      console.error(
        'file: index.jsx:23 ~ handleWrapOrConnectClick ~ err:',
        err
      );
      setLoading(false);

      if (
        err?.message
          ?.toLowerCase()
          ?.includes('transaction was not mined within 50 blocks')
      ) {
        return toast.warn(err.message);
      }
      toast.error(err.message);
    } finally {
      setLoading(false);
      await resetState();
      await handleTotalBalance();
    }
  };

  // Get Current Token Balance
  const handleTotalBalance = async () => {
    if (!from) {
      return;
    }
    const web3 = await getWeb3();
    const connectedAccount = await web3.eth.getAccounts();
    if (from.name === 'StorageChain') {
      const balance = await web3.eth.getBalance(connectedAccount[0]);
      const balanceInEth = Number(web3.utils.fromWei(balance, 'ether'));

      // if (balanceInEth?.toString()?.includes('e')) {
      //   return setCurrentTokenBalance(Number(balanceInEth)?.toFixed(8));
      // }
      return setCurrentTokenBalance(balanceInEth);
    }
    const balance = await getBalanceOfEthContractByWalletAddress(
      connectedAccount[0]
    );
    console.log('handleTotalBalance ~ balance:', balance);
    return setCurrentTokenBalance(Number(balance)?.toFixed(8));
  };

  // Gas fee calculations.
  const calculateNetworkFee = async () => {
    try {
      setFetchingGasFee(true);
      if (!to?.name) {
        return false;
      }
      const networkGasFees = await getGasEstimations(
        to?.name?.toLowerCase(),
        to?.symbol,
        connectedWallet?.walletAddress,
        from?.value?.toFixed(2) || 100
      );
      console.log('calculateNetworkFee ~ networkGasFees:', networkGasFees);
      setFeeDeductions(networkGasFees?.data);
    } catch (err) {
      toast.error(err.message);
      console.error('calculateNetworkFee ~ err:', err);
    } finally {
      setFetchingGasFee(false);
    }
  };

  useEffect(() => {
    console.log('connected Wallet', connectedWallet);
    console.log('file: index.jsx:140 ~ calculateNetworkFee ~ from:', from);

    calculateNetworkFee();
  }, [connectedWallet?.network?.id, to?.name, from?.name]);

  useEffect(() => {
    const receiveValueUpdate = () => {
      if (from?.value && feeDeductions?.storCoinDeductionValue) {
        const approxReceiveTokens =
          from?.value - feeDeductions?.storCoinDeductionValue;
        if (approxReceiveTokens < 0) {
          setTo({ ...to, value: 0 });
          return;
        }
        setTo({ ...to, value: approxReceiveTokens });
      }
    };
    receiveValueUpdate();
  }, [feeDeductions?.storCoinDeductionValue]);

  useEffect(() => {
    if (connectedWallet?.walletAddress === '') {
      setCurrentTokenBalance(0);
    }
    handleTotalBalance();
  }, [from?.name, connectedWallet?.walletAddress]);

  return (
    <div className={style.swapcoin}>
      <div className='wrapper'>
        <div className='inner-wrap'>
          <div className='top-bar'>
            <div className='btn-wrap'>
              <button className='btn'>Wrap</button>
            </div>
          </div>

          <SwapSection
            currentTokenBalance={currentTokenBalance}
            gasFeeDeduction={feeDeductions}
            allowChangeNetwork
            showTotalBalance
            label='You Pay'
            token={from}
            otherToken={to}
            setChain={setFrom}
            setOtherChain={setTo}
            setConnectedWallet={setConnectedWallet}
            // gasFeeLoading={fetchingGasFee}
          />
          <div className='arrow-wrapper'>
            <div className='arrow-btn' onClick={swapCoin}>
              <SVG.Arrow />
            </div>
          </div>
          <div className='bottom-section'>
            <SwapSection
              disabledInput
              label='You Receive (Approx.)'
              token={to}
              otherToken={from}
              setChain={setTo}
              setOtherChain={setFrom}
              gasFeeLoading={fetchingGasFee}
              setConnectedWallet={setConnectedWallet}
            />
            <FeeDetail
              fetchingGasEstimations={fetchingGasFee}
              title='Gas Fee Deduction'
              fee={feeDeductions?.transactionFeeInUsd}
              feeInEth={parseFloat(feeDeductions?.transactionFeeInEth)}
              feeInStor={parseFloat(feeDeductions?.storCoinDeductionValue)}
            />
            <button
              className='connect-btn'
              onClick={handleWrapTokens}
              disabled={
                !connectedWallet?.walletAddress ||
                loading ||
                to?.value === 0 ||
                !feeDeductions?.transactionFeeInUsd ||
                from?.symbol === to?.symbol
              }
            >
              {loading ? (
                <Spinner animation='border' />
              ) : (
                <>
                  {CONVERSION_BUTTON_TEXT[from?.symbol] ||
                    'PLEASE SELECT THE TOKEN'}
                </>
              )}
            </button>
            <p className='text-center my-3 fs-sm'>
              Unable to import WSTOR token?{' '}
              <span className='link' onClick={handleImportTokens}>
                Import WSTOR
              </span>
            </p>
          </div>
          {from?.name?.toLowerCase() === 'polygon' && (
            <div className='warning-section'>
              <CiWarning
                className='text-warning'
                style={{ fontSize: '100px' }}
              />
              <p style={{ fontSize: '12px' }}>
                Due to a high volume of transactions, estimated gas prices may
                be less accurate. If the processing time for your transaction is
                unknown, please consider adjusting the gas price manually in
                MetaMask for better control.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SwapCoin;
