import React, { useState } from 'react';
import WrapContext from './WrapContext';

const ContextProvider = ({ children }) => {
  const [network, setNetwork] = useState('');
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');

  return (
    <WrapContext.Provider
      value={{ network, setNetwork, from, setFrom, to, setTo }}
    >
      {children}
    </WrapContext.Provider>
  );
};

export default ContextProvider;
