import Web3 from 'web3';
import { networks } from './networksParams';
import { NETWORKS } from '../utils/constants';

const isMetaMaskAvailable = () => {
  if (typeof window.ethereum !== 'undefined') {
    return true;
  }
  return false;
};

const getWeb3 = async () => {
  if (isMetaMaskAvailable()) {
    const web3 = new Web3(window.ethereum);

    return web3;
  }
};

const getCustomRPCWeb3Instance = async (RPC) => {
  if (isMetaMaskAvailable()) {
    const web3Provider = new Web3.providers.HttpProvider(RPC);
    const web3 = new Web3(web3Provider);

    return web3;
  }
};

const getChainId = async () => {
  return parseInt(await window.ethereum.request({ method: 'eth_chainId' }));
};

const addMultichainNetwork = async (networkParams) => {
  console.log(
    'file: index.jsx:59 ~ addMultichainNetwork ~ networkParams:',
    networkParams
  );
  try {
    await window.ethereum.request({
      method: 'wallet_addEthereumChain',
      params: [networkParams],
    });
  } catch (err) {
    console.log('🚀 ~ file: config.tsx ~ line 37 ~ err', err);
  }
};

const addingChainNetworkIfNotExsists = async (chainId, networkParams) => {
  try {
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId }],
    });
    return true;
  } catch (err) {
    console.error(
      'file: index.jsx:77 ~ addingChainNetworkIfNotExsists ~ err:',
      err
    );
    if (err.code === 4902) {
      try {
        await addMultichainNetwork(networkParams);
        return true;
      } catch (err) {
        throw err;
      }
    }
    throw err;
  }
};

const checkForMetamaskNetwork = async (selectedBlockChainType) => {
  if (!isMetaMaskAvailable()) return false;
  const chainId = await getChainId();

  if (selectedBlockChainType === 'storagechain') {
    console.log(
      'this is running...',
      process.env.REACT_APP_NETWORK === 'devnet'
    );

    if (process.env.REACT_APP_NETWORK === 'devnet') {
      return addingChainNetworkIfNotExsists(
        '0x2217',
        networks.storagechain.testnet
      );
    }

    if (process.env.REACT_APP_NETWORK === 'mainnet') {
      return addingChainNetworkIfNotExsists(
        '0x2216',
        networks.storagechain.mainnet
      );
    }
  }

  if (selectedBlockChainType === 'ethereum') {
    if (process.env.REACT_APP_NETWORK !== 'devnet' && chainId !== 1) {
      return addingChainNetworkIfNotExsists('0x1', networks.ethereum.mainnet);
    }

    if (process.env.REACT_APP_NETWORK === 'devnet' && chainId !== 11155111) {
      return addingChainNetworkIfNotExsists(
        '0xaa36a7',
        networks.ethereum.testnet
      );
    }
    return true;
  }

  // if (selectedBlockChainType === 'avalanche') {
  //   if (process.env.REACT_APP_NETWORK !== 'devnet' && chainId !== 43114) {
  //     return addingChainNetworkIfNotExsists(
  //       '0xa86a',
  //       networks.avalanche.mainnet
  //     );
  //   }

  //   if (process.env.REACT_APP_NETWORK === 'devnet' && chainId !== 43113) {
  //     return addingChainNetworkIfNotExsists(
  //       '0xa869',
  //       networks.avalanche.testnet
  //     );
  //   }
  //   return true;
  // }

  if (selectedBlockChainType === 'arbitrum') {
    if (process.env.REACT_APP_NETWORK !== 'devnet' && chainId !== 42161) {
      return addingChainNetworkIfNotExsists(
        '0xa4b1',
        networks.arbitrum.mainnet
      );
    }

    if (process.env.REACT_APP_NETWORK === 'devnet' && chainId !== 421614) {
      return addingChainNetworkIfNotExsists(
        '0x66eee',
        networks.arbitrum.testnet
      );
    }
    return true;
  }

  if (process.env.REACT_APP_NETWORK !== 'devnet' && chainId !== 137) {
    return addingChainNetworkIfNotExsists('0x89', networks.polygon.mainnet);
  }

  if (process.env.REACT_APP_NETWORK === 'devnet' && chainId !== 80002) {
    return addingChainNetworkIfNotExsists('0x13882', networks.polygon.testnet);
  }
  return true;
};

const getAccounts = async () => {
  const accounts = await window.ethereum.request({
    method: 'eth_requestAccounts',
  });
  return accounts;
};

const getAccountBalance = async (walletAddress, selectedBlockChainType) => {
  const web3 = await getWeb3(selectedBlockChainType);
  return web3.utils.fromWei(await web3.eth.getBalance(walletAddress));
};

const getAccountInformation = async (selectedBlockChainType, walletAddress) => {
  try {
    const accounts = await getAccounts();
    console.log('getAccountInformation ~ accounts:', accounts);
    const balance = await getAccountBalance(
      walletAddress || accounts[0],
      selectedBlockChainType
    );
    return { accounts, balance };
  } catch (err) {
    console.log(
      '🚀 ~ file: config.tsx ~ line 104 ~ getAccountInformation ~ err',
      err
    );
    if (err?.code === -32002) {
      return;
    }
    throw new Error(err?.message);
  }
};
const connect = async (selectedBlockChainType) => {
  const data = await getAccountInformation(selectedBlockChainType);
  const wallet = {
    walletAddress: data?.accounts[0],
    balance: data?.balance,
    network: NETWORKS.find((net) => net?.id === selectedBlockChainType),
  };
  return wallet;
};

const loginWithMetaMask = async (selectedBlockChainType) => {
  console.log(
    'loginWithMetaMask ~ selectedBlockChainType:',
    selectedBlockChainType
  );
  try {
    const networkResponse = await checkForMetamaskNetwork(
      selectedBlockChainType
    );

    if (!networkResponse) {
      return -1;
    }
    const walletInformation = await connect(selectedBlockChainType);
    console.log(
      'file: index.jsx:209 ~ loginWithMetaMask ~ walletInformation:',
      walletInformation
    );

    return walletInformation;
  } catch (err) {
    console.log(
      '🚀 ~ file: config.tsx ~ line 138 ~ loginWithMetaMask ~ err',
      err
    );
    throw new Error(err?.message);
  }
};

export {
  getWeb3,
  isMetaMaskAvailable,
  checkForMetamaskNetwork,
  getAccountInformation,
  connect,
  loginWithMetaMask,
  getCustomRPCWeb3Instance,
};
