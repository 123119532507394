import axios from 'axios';

// gasFeeEstimations/storagechain/STOR/0xAAD7D223E95acE70f58cCF7Bf387Ddd651C33403/100
const getGasEstimations = async (chain, coin, walletAddress, amount) => {
  try {
    const gasEstimationResponse = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_URL}contract-function/gasFeeEstimations/${chain}/${coin}/${walletAddress}/${amount}`,
    });
    return gasEstimationResponse?.data;
  } catch (err) {
    console.error('getGasEstimations ~ err:', err);
    throw new Error(err?.response?.data?.message || err.message);
  }
};

const addTransaction = async (payload) => {
  try {
    const addTransactionResponse = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_URL}contract-function/add-transaction`,
      data: { payload },
    });
    return addTransactionResponse?.data;
  } catch (err) {
    console.error('addTransaction ~ err:', err);
    throw new Error(err?.response?.data?.message || err.message);
  }
};

const getListOfTransactionHashByWalletAddress = async (
  walletAddress,
  params
) => {
  try {
    const transactionsResponse = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_URL}contract-function/transactions/${walletAddress}`,
      params,
    });

    return transactionsResponse?.data?.data;
  } catch (err) {
    throw new Error(err?.response?.data?.message || err.message);
  }
};

export {
  getGasEstimations,
  addTransaction,
  getListOfTransactionHashByWalletAddress,
};
