/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo, useContext } from 'react';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import { getListOfTransactionHashByWalletAddress } from '../../server';
import { CHAIN_TOKEN_SYMBOL, LIMIT } from '../../utils/constants';
import Pagination from '../Pagination';
import ToolTip from '../ToolTip';
import SocketContext from '../../context/SocketContext';
import { Spinner } from 'react-bootstrap';

dayjs.extend(relativeTime);

function Transactions({ connectedAccount, web3Instance }) {
  // Context
  const socket = useContext(SocketContext);
  // States
  const [transactions, setTransactions] = useState([]);
  console.log('Transactions ~ transactions:', transactions);

  // const [connectedAccount, setConnectedAccount] = useState(null);
  const [page, setPage] = useState(0);

  // Helper Functions
  const getTransactionHashByChain = (chainName) => {
    const TRANSACTION_KEY_BY_CHAIN = {
      ethereum: 'txnHashWStor',
      polygon: 'txnHashWStor',
      storagechain: 'txnHashStor',
    };
    return TRANSACTION_KEY_BY_CHAIN[chainName] || 'txnHashStor';
  };

  // UseEffects

  const getListOfTransactions = async () => {
    if (connectedAccount?.walletAddress) {
      const transactionResponse = await getListOfTransactionHashByWalletAddress(
        connectedAccount?.walletAddress,
        {
          page,
          limit: LIMIT,
        }
      );
      setTransactions(transactionResponse);
    }
  };

  useEffect(() => {
    getListOfTransactions();
  }, [connectedAccount?.walletAddress, page]);

  useEffect(() => {
    if (socket) {
      socket.on('newTransaction', () => {
        getListOfTransactions(connectedAccount?.walletAddress, {
          page,
          limit: LIMIT,
        });
      });
      socket.on('transactionUpdated', () => {
        getListOfTransactions(connectedAccount?.walletAddress, {
          page,
          limit: LIMIT,
        });
      });
    }
  }, [socket]);
  return (
    <div className='mainTable container p-0'>
      <div className='table-responsive'>
        <table>
          <thead>
            <tr>
              <th>Transaction Hash</th>
              <th>Status</th>
              <th>Message</th>
              <th>Conversion Type</th>
              <th>Actual Conversion Amount</th>
              <th>Gas Fee Deduction</th>
              <th>Amount Transferred</th>
              <th>From</th>
              <th>To</th>
              <th>Date/Time</th>
            </tr>
          </thead>
          <tbody>
            {transactions?.data?.length > 0 &&
              transactions?.data?.map((txn) => (
                <tr key={txn?._id}>
                  <td>
                    <ToolTip
                      text={txn[getTransactionHashByChain(txn?.fromBlockchain)]}
                      component={
                        <p className='shortened-string'>
                          {txn[getTransactionHashByChain(txn?.fromBlockchain)]}
                        </p>
                      }
                    />
                  </td>
                  <td>
                    <div className='pendingTransaction'>
                      {txn?.status === 'pending' && (
                        <Spinner animation='border' size='sm' />
                      )}
                      <p>{txn?.status?.toString()?.toUpperCase()}</p>
                    </div>
                  </td>
                  <td>
                    <ToolTip
                      text={txn?.message}
                      component={
                        <p className='shortened-string'>{txn?.message}</p>
                      }
                    />
                  </td>
                  <td>{txn?.conversionType}</td>
                  <td>{`${web3Instance?.utils?.fromWei(
                    txn?.txnAmount?.toString() || '0'
                  )} ${
                    CHAIN_TOKEN_SYMBOL[txn?.fromBlockchain || 'storagechain']
                  }`}</td>
                  <td>{Number(txn?.gasFee || 0)?.toFixed(6)} STOR</td>
                  <td>{`${
                    Number(txn?.amountTransferred || 0)?.toFixed(6) || 0
                  } ${
                    CHAIN_TOKEN_SYMBOL[txn?.toBlockchain || 'storagechain']
                  }`}</td>
                  <td>{txn?.fromBlockchain}</td>
                  <td>{txn?.toBlockchain}</td>
                  <td>
                    <ToolTip
                      text={dayjs(txn?.createdAt).format(
                        'dddd MMMM, YYYY hh:mm:ss A'
                      )}
                      component={<p>{dayjs(txn?.createdAt).fromNow()}</p>}
                    />
                  </td>
                </tr>
              ))}
            {transactions?.data?.length === 0 && (
              <td colSpan={8}>
                {`No transaction record found for ${connectedAccount?.walletAddress}`}
              </td>
            )}
          </tbody>
        </table>
        {transactions?.pagination && (
          <Pagination
            page={transactions?.pagination?.page}
            currentPage={page}
            setCurrentPage={setPage}
            total={Math.ceil((transactions?.pagination?.total || 1) / LIMIT)}
          />
        )}
      </div>
    </div>
  );
}
export default Transactions;
