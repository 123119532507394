import React from 'react';

const WrapContext = React.createContext({
  network: {},
  from: {},
  to: {},
  setNetwork: () => {},
  setFrom: () => {},
  setTo: () => {},
});

export default WrapContext;
