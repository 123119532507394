import React from 'react';
import { Modal, ListGroup } from 'react-bootstrap';
import { loginWithMetaMask } from '../../web3';
import { toast } from 'react-toastify';

const ChainModal = ({
  show,
  handleClose,
  chains,
  setSelectedChain,
  setConnectedWallet,
  allowChangeNetwork,
}) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Select a token</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ListGroup>
          {chains.map((chain) => (
            <ListGroup.Item
              key={chain.name}
              action
              onClick={async () => {
                try {
                  if (allowChangeNetwork) {
                    const loginResponse = await loginWithMetaMask(
                      chain.name?.toLowerCase()
                    );
                    setConnectedWallet(loginResponse);
                  }
                  setSelectedChain(chain);
                  handleClose();
                } catch (err) {
                  console.error(
                    'file: ChainModal.jsx:31 ~ onClick={ ~ err:',
                    err
                  );
                  toast.error(err.message);
                }
              }}
            >
              <div className='chain-img'>
                <img src={chain.icon} alt={chain.name} />
              </div>
              <div className='chain-name'>
                {chain.name}
                <span>{chain.symbol}</span>
              </div>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Modal.Body>
    </Modal>
  );
};

export default ChainModal;
