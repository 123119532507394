import { io } from 'socket.io-client';

let socket = null;

const connectSocket = () => {
  if (!socket) {
    const socketInstance = new io(process.env.REACT_APP_WSS_URL);

    return socketInstance;
  }
};

export default { connectSocket, socket };
