/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import ChainModal from './ChainModal';
import { toast } from 'react-toastify';
import Spinner from 'react-bootstrap/Spinner';

import ToolTip from '../ToolTip';
import { CHAINS } from '../../utils/constants';

function SwapSection({
  gasFeeDeduction,
  showTotalBalance,
  disabledInput,
  label,
  token,
  otherToken,
  setChain,
  setOtherChain,
  setConnectedWallet,
  allowChangeNetwork,
  currentTokenBalance,
  gasFeeLoading,
}) {
  console.log('token:', token, otherToken);
  const [showModal, setShowModal] = useState(false);
  // const chains = ;

  const handleInputChange = (event) => {
    try {
      console.log(
        'file: SwapSection.jsx:32 ~ handleInputChange ~ event:',
        event.target.value
      );
      console.log('token', token);
      if (!token) {
        throw new Error('Please select token.');
      }

      setChain({ ...token, value: parseFloat(event.target.value) });

      if (!otherToken) {
        return setOtherChain({
          ...(token?.name === 'StorageChain' ? CHAINS[0] : CHAINS[2]),
          value: parseFloat(event.target.value),
        });
      }
      const totalGotPrice =
        (event.target.value || 0) -
          (gasFeeDeduction?.storCoinDeductionValue || 0) <
        0
          ? 0
          : (event.target.value || 0) -
            (gasFeeDeduction?.storCoinDeductionValue || 0);
      setOtherChain({
        ...otherToken,
        value: parseFloat(totalGotPrice),
      });
    } catch (err) {
      console.error('file: SwapSection.jsx:33 ~ handleInputChange ~ err:', err);
      toast.error(err.message);
    }
  };

  const handleMaxClick = async () => {
    if (!token) return;
    let value = 0;
    if (token?.name?.toLowerCase() === 'storagechain') {
      value = Number(currentTokenBalance) - 0.1;
    } else {
      const numberStr = currentTokenBalance.toString();
      const selectedNumber = numberStr.slice(0, numberStr?.length - 1);
      value = Number(selectedNumber);
    }

    // Check if value is in negative
    if (value < 0) {
      value = 0;
    }

    setChain({
      ...token,
      value,
    });
    let otherCoinValue = Number(
      value - gasFeeDeduction?.storCoinDeductionValue
    );

    if (otherCoinValue < 0) {
      otherCoinValue = 0;
    }
    if (!otherToken) {
      return setOtherChain({
        ...(token?.name === 'StorageChain' ? CHAINS[0] : CHAINS[2]),
        value: otherCoinValue,
      });
    }
    setOtherChain({
      ...otherToken,
      value: otherCoinValue,
    });
  };

  return (
    <>
      <div className='swap-section'>
        <div className='swap-currency-wrapper'>
          <div className='input-container'>
            <div className='input-label'>{label}</div>
            <div className='input-panel'>
              <div className='input-box'>
                {gasFeeLoading ? (
                  <Spinner animation='border' size='sm' color='white' />
                ) : (
                  <input
                    disabled={!token || disabledInput}
                    className='token-amount-input'
                    id='swap-currency-input'
                    type='number'
                    placeholder='0'
                    min={0}
                    onChange={handleInputChange}
                    value={token?.value}
                  />
                )}
              </div>
              <div>
                <div className='popover-element'>
                  <ToolTip
                    text={token?.info}
                    // component={<button>Hello World!</button>}
                    component={
                      <button
                        className={`open-currency-select-button ${
                          !token && 'unselected'
                        }`}
                        onClick={() => setShowModal(true)}
                      >
                        <span className='swap-currency-input-panel'>
                          <div className='icon'>
                            {token && (
                              <div className='logo-container'>
                                {token?.icon ? (
                                  <img src={token?.icon} alt='' />
                                ) : (
                                  <div className='missing-image-logo'>WST</div>
                                )}
                              </div>
                            )}
                            <span className='token-symbol-container'>
                              {token ? token?.symbol : 'Select token'}
                            </span>
                          </div>
                          <svg
                            width='12'
                            height='7'
                            viewBox='0 0 12 7'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                            className='drop-down-chev'
                          >
                            <path
                              d='M0.97168 1L6.20532 6L11.439 1'
                              stroke='currentColor'
                            ></path>
                          </svg>
                        </span>
                      </button>
                    }
                  />
                </div>
              </div>
            </div>
            {showTotalBalance && (
              <div className='bottom-text'>
                <div className='inner-wrapper'>
                  <div className='loading-container'>
                    <div className='text-wrapper'>
                      <div className='fiat-value'>
                        <div>{currentTokenBalance || 0}</div>
                      </div>
                    </div>
                  </div>
                  <span className='max' onClick={handleMaxClick}>
                    Max
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <ChainModal
        allowChangeNetwork={allowChangeNetwork}
        show={showModal}
        handleClose={() => setShowModal(false)}
        chains={CHAINS}
        setSelectedChain={setChain}
        setConnectedWallet={setConnectedWallet}
      />
    </>
  );
}

export default SwapSection;
