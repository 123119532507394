import React from 'react';
import { Accordion, Badge, Spinner } from 'react-bootstrap';
import SVG from '../../assets/images/images';

function FeeDetail({
  fetchingGasEstimations,
  title,
  fee,
  feeInStor,
  feeInEth,
  networkCost,
}) {
  const data = {
    title: title,
    fee: networkCost,
    details: [
      { isDivider: true },
      { label: 'Fee In USD', value: `${fee?.toFixed(7)} $` },
      { label: 'Fee in STOR', value: `${feeInStor?.toFixed(7)} STOR` },
      // { label: 'Fee in ETH', value: `${feeInEth?.toFixed(7)} ETH` },

      // {
      //   label: 'Max. slippage',
      //   badgeTitle: 'Auto',
      //   value: maxSlippage,
      //   type: 'badge',
      // },
      // { label: 'Network Cost', value: networkCost },
      // { isDivider: true },
      // { label: 'Order Routing', value: orderRouting },
    ],
  };

  return (
    <Accordion defaultActiveKey={null}>
      <Accordion.Item>
        <Accordion.Header>
          <div className='d-flex justify-content-between w-100'>
            {data.title}
            {fee !== 0 && (
              <span className='me-2'>
                {fetchingGasEstimations ? (
                  <Spinner animation='border' size='sm' />
                ) : (
                  <>
                    <SVG.FeeIcon /> {fee?.toFixed(5)} $
                  </>
                )}
              </span>
            )}
          </div>
        </Accordion.Header>
        <Accordion.Body>
          {data.details &&
            data.details.map((detail, detailIndex) => (
              <React.Fragment key={detailIndex}>
                {detail.isDivider && <div className='divider' />}
                {(detail.label || detail.value) && (
                  <div className='d-flex align-items-center justify-content-between'>
                    <div>{detail.label}</div>
                    {detail.type === 'badge' ? (
                      <div className='d-flex align-items-center'>
                        <Badge
                          bg='light'
                          text='dark'
                          className='me-2 fw-normal'
                        >
                          {detail.badgeTitle}
                        </Badge>
                        <span>{detail.value}</span>
                      </div>
                    ) : (
                      <span>{detail.value}</span>
                    )}
                  </div>
                )}
              </React.Fragment>
            ))}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default FeeDetail;
