// import SVG from ',./../assets/images/images';
import SVG, { Images } from '../assets/images/images';
export const ETHEREUM_CHAIN = 'ethereum';
export const POLYGON_CHAIN = 'polygon';
export const STORAGECHAIN = 'storagechain';
export const POLYGON_API = 'polygon_mombai';
export const TOTAL_SUPPLY_MESSAGE = 'Get Total Supply for --{coin-name}--';

export const CHAIN_CURRENCY_BY_CHAIN_NAME = {
  polygon: 'MATIC',
  ethereum: 'ETH',
  goerli: 'GoerliETH',
  sepolia: 'SepoliaETH',
  storagechain: 'STOR',
};

export const CHAINS_BY_CHAIN_ID = {
  80001: 'Polygon Matic',
  80002: 'Polygon Matic',
  137: 'Polygon Matic',
  8726: 'StorageChain',
  8727: 'StorageChain',
  1998: 'StorageChain',
  5: 'Goerli',
  11155111: 'Sepolia',
  1: 'Ethereum',
};

export const CURRENT_SELECTED_NETWORK = {
  80001: 'polygon',
  80002: 'polygon',
  137: 'polygon',
  8726: 'storagechain',
  8727: 'storagechain',
  1998: 'storagechain',
  5: 'goerli',
  11155111: 'sepolia',
  1: 'ethereum',
};

export const CHAIN_COIN_NAME = {
  80001: 'WSTOR',
  80002: 'WSTOR',
  137: 'WSTOR',
  8726: 'STOR',
  8727: 'STOR',
  1998: 'STOR',
  5: 'WSTOR',
  1: 'WSTOR',
  11155111: 'WSTOR',
};

export const CHAIN_ICONS = {
  ethereum: {
    url: 'https://ipfs.io/ipfs/QmdwQDr6vmBtXmK2TmknkEuZNoaDqTasFdZdu3DRw8b2wt',
    width: 10,
    height: 16,
  },
  polygon: {
    url: 'https://ipfs.io/ipfs/QmRNqgazYuxUa5WdddFPftTWiP3KwzBMgV9Z19QWnLMETc',
    width: 10,
    height: 10,
  },
  storagechain: {
    url: 'https://ipfs.io/ipfs/QmfN9r1S7FvzkbjqfNMdUikhHmCw5e6UqsTuRD51S8T6Cq',
    width: 10,
    height: 10,
  },
  polygon_mombai: {
    url: 'https://ipfs.io/ipfs/QmRNqgazYuxUa5WdddFPftTWiP3KwzBMgV9Z19QWnLMETc',
    width: 10,
    height: 10,
  },
  goerli: {
    url: 'https://ipfs.io/ipfs/QmdwQDr6vmBtXmK2TmknkEuZNoaDqTasFdZdu3DRw8b2wt',
    width: 10,
    height: 16,
  },
  sepolia: {
    url: 'https://ipfs.io/ipfs/QmdwQDr6vmBtXmK2TmknkEuZNoaDqTasFdZdu3DRw8b2wt',
    width: 10,
    height: 16,
  },
};

export const CHAIN_NAME_BY_CHAIN_ID = {
  1: 'ethereum',
  5: 'ethereum',
  11155111: 'ethereum',
  137: 'polygon',
  80001: 'polygon',
  80002: 'polygon',
  8726: 'storagechain',
  8727: 'storagechain',
  1998: 'storagechain',
  // 43114: 'avalanche',
  // 43113: 'avalanche',
  42161: 'arbitrum',
  421614: 'arbitrum',
};
export const NETWORKS = [
  {
    id: 'ethereum',
    label: 'Ethereum',
    logo: <SVG.Ethereum />,
  },
  {
    id: 'polygon',
    label: 'Polygon',
    logo: <SVG.Polygon />,
  },
  {
    id: 'storagechain',
    label: 'StorageChain',
    logo: <SVG.Coin />,
  },
  // {
  //   id: 'avalanche',
  //   label: 'Avalanche',
  //   logo: <SVG.AvalancheIcon />,
  // },
  {
    id: 'arbitrum',
    label: 'Arbitrum',
    logo: <SVG.ArbitrumIcon />,
  },
];

export const NETWORKS_BY_NAME = {
  ethereum: NETWORKS[0],
  polygon: NETWORKS[1],
  storagechain: NETWORKS[2],
  // avalanche: NETWORKS[3],
  arbitrum: NETWORKS[3],
};

export const CONVERSION_BUTTON_TEXT = {
  STOR: 'WRAP',
  WSTOR: 'UNWRAP',
};

export const CONTRACT_FUNCTION_NAMES = {
  STOR: {
    name: 'transferStorToUser',
    args: [process.env.REACT_APP_ADMIN_WALLET_ADDRESS, 10000000000000],
  },
  WSTOR: {
    name: 'burn',
    args: [10000000000000],
  },
};

export const LIMIT = 20;

export const CONTRACT_ADDRESSES_BY_CHAIN = {
  polygon: process.env.REACT_APP_POLYGON_CONTRACT_ADDRESS,
  ethereum: process.env.REACT_APP_ETHEREUM_CONTRACT_ADDRESS,
  // avalanche: process.env.REACT_APP_AVALANCHE_CONTRACT_ADDRESS,
  arbitrum: process.env.REACT_APP_ARBITRUM_CONTRACT_ADDRESS,
};

export const CHAIN_TOKEN_SYMBOL = {
  polygon: 'WSTOR',
  ethereum: 'WSTOR',
  storagechain: 'STOR',
  // avalanche: 'WSTOR',
  arbitrum: 'WSTOR',
};

export const CHAINS = [
  {
    name: 'Ethereum',
    icon: Images.EthImage,
    symbol: 'WSTOR',
    info: 'WSTOR on Ethereum Chain.',
    value: null,
  },
  {
    name: 'Polygon',
    icon: Images.PolygonImage,
    symbol: 'WSTOR',
    info: 'WSTOR on Polygon Chain',
    value: null,
  },
  {
    name: 'StorageChain',
    icon: Images.STORCoin,
    symbol: 'STOR',
    info: 'STOR on Storagechain.',
    value: null,
  },
  // {
  //   name: 'Avalanche',
  //   icon: Images.Avalanche,
  //   symbol: 'WSTOR',
  //   info: 'WSTOR on Avalanche.',
  //   value: null,
  // },
  {
    name: 'Arbitrum',
    icon: Images.Arbitrum,
    symbol: 'WSTOR',
    info: 'WSTOR on Arbitrum.',
    value: null,
  },
];

export const CHAINS_BY_NETWORK_NAME = {
  storagechain: CHAINS[2],
  polygon: CHAINS[1],
  ethereum: CHAINS[0],
  // avalanche: CHAINS[3],
  arbitrum: CHAINS[3],
};
