import { ReactComponent as Logo } from '../assets/images/logo.svg';
import NetworkDropdown from './SwapCoin/NetworkDropdown';
import SwapCoin from './SwapCoin';
import Transactions from './Transactions';
import { useContext, useEffect, useState } from 'react';
import { getWeb3 } from '../web3';
import SocketContext from '../context/SocketContext';
import WrapContext from '../context/WrapContext';

function Dashboard({ connectedWallet, setConnectedWallet }) {
  const { setFrom, setTo } = useContext(WrapContext);
  console.log('Dashboard ~ setTo:', setTo);

  const socket = useContext(SocketContext);

  const [web3Instance, setWeb3Instance] = useState(null);

  useEffect(() => {
    const initWeb3 = async () => {
      const web3 = await getWeb3();
      setWeb3Instance(web3);
    };
    initWeb3();
  }, []);

  useEffect(() => {
    if (window?.ethereum) {
      window?.ethereum?.on('accountsChanged', async (accounts) => {
        if (accounts?.length === 0) {
          setTo(null);
          setFrom(null);
          return setConnectedWallet({
            walletAddress: '',
            balance: 0,
          });
        }
        if (accounts?.length > 0) {
          setConnectedWallet({
            ...connectedWallet,
            balance: 0,
            walletAddress: accounts[0],
          });
        }
      });
    }
  });

  useEffect(() => {
    if (socket && connectedWallet?.walletAddress) {
      socket.emit('join', connectedWallet?.walletAddress?.toLowerCase());
    }
    return () => {
      if (socket) {
        socket.emit('leave', connectedWallet?.walletAddress);
      }
    };
  }, [socket, connectedWallet?.walletAddress]);

  if (!web3Instance) {
    return (
      <div>
        Waiting for the Web3 instance to become available. Please install
        MetaMask and refresh the page.
      </div>
    );
  }

  return (
    <div className='dashboard'>
      <div className='header'>
        <div className='inner-div'>
          <a href='https://storagechain.io/'>
            <Logo className='logo' />
          </a>

          <NetworkDropdown
            web3Instance={web3Instance}
            connectedWallet={connectedWallet}
            setConnectedWallet={setConnectedWallet}
          />
        </div>
      </div>

      <SwapCoin
        connectedWallet={connectedWallet}
        setConnectedWallet={setConnectedWallet}
      />
      {connectedWallet?.walletAddress && (
        <Transactions
          connectedAccount={connectedWallet}
          web3Instance={web3Instance}
        />
      )}
    </div>
  );
}
export default Dashboard;
