import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

function ToolTip({ text, component, className = '' }) {
  const renderTooltip = (props) => (
    <Tooltip id='first-tooltip' {...props}>
      {text}
    </Tooltip>
  );

  if (!text) return component;

  return (
    <OverlayTrigger placement='top' overlay={renderTooltip}>
      <div className={className}>{component}</div>
    </OverlayTrigger>
  );
}

export default ToolTip;
