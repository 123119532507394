import React from 'react';

function Pagination({ currentPage, setCurrentPage, page, total, disabled }) {
  return (
    <div className='pagination'>
      <div className='pages'>
        Page {page + 1} of {total}
      </div>
      <div className='prevNext'>
        {currentPage > 0 && (
          <button
            disabled={disabled}
            onClick={() => setCurrentPage(currentPage - 1)}
          >
            Previous
          </button>
        )}
        {total > currentPage + 1 && (
          <button
            disabled={disabled}
            onClick={() => setCurrentPage(currentPage + 1)}
          >
            Next
          </button>
        )}
      </div>
    </div>
  );
}

export default Pagination;
