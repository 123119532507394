export const getCoinMetadata = (contractAddress) => {
  if (!contractAddress) return null;
  return {
    type: 'ERC20',
    options: {
      address: contractAddress, // The address that the token is at.
      symbol: 'WSTOR',
      decimals: 18,
      image: 'https://storagechain.io/coin.svg',
    },
  };
};

export const networks = {
  storagechain: {
    mainnet: {
      chainId: '0x2216',
      chainName: 'StorageChain Mainnet',
      nativeCurrency: {
        name: 'Storchain',
        symbol: 'STOR',
        decimals: 18,
      },
      rpcUrls: [process.env.REACT_APP_MAINNNET_STORAGE_CHAIN_RPC_URL],
      blockExplorerUrls: [process.env.REACT_APP_BLOCK_EXPLORER_URL],
    },
    testnet: {
      chainId: '0x2217',
      chainName: 'StorageChain Testnet',
      nativeCurrency: {
        name: 'Storchain',
        symbol: 'STOR',
        decimals: 18,
      },

      rpcUrls: [process.env.REACT_APP_TESTNET_STORAGE_CHAIN_RPC_URL],
      blockExplorerUrls: [process.env.REACT_APP_BLOCK_EXPLORER_URL],
    },
  },
  ethereum: {
    mainnet: {
      chainId: '0x1',
      chainName: 'Ethereum Mainnet',
      nativeCurrency: {
        name: 'Ethereum',
        symbol: 'ETH',
        decimals: 1,
      },
      rpcUrls: [process.env.REACT_APP_ETHEREUM_RPC_URL],
      blockExplorerUrls: [process.env.REACT_APP_ETHEREUM_BLOCK_EXPLORER_URL],
    },
    testnet: {
      chainId: '0xaa36a7',
      chainName: 'Sepolia Testnet',
      nativeCurrency: {
        name: 'Sepolia Ethereum',
        symbol: 'SepoliaETH',
        decimals: 11155111,
      },
      rpcUrls: [process.env.REACT_APP_SEPOLIA_RPC_URL],
      blockExplorerUrls: [process.env.REACT_APP_SEPOLIA_BLOCK_EXPLORER_URL],
    },
  },

  polygon: {
    mainnet: {
      chainId: '0x89',
      chainName: 'Polygon Mainnet',
      nativeCurrency: {
        name: 'MATIC',
        symbol: 'MATIC',
        decimals: 18,
      },
      rpcUrls: [process.env.REACT_APP_POLYGON_MAINNET_RPC_URL],
      blockExplorerUrls: [
        process.env.REACT_APP_POLYGON_MAINNET_BLOCK_EXPLORER_URL,
      ],
    },
    testnet: {
      chainId: '0x13882',
      chainName: 'Polygon Testnet',
      nativeCurrency: {
        name: 'MATIC',
        symbol: 'MATIC',
        decimals: 18,
      },
      rpcUrls: [process.env.REACT_APP_POLYGON_TESTNET_RPC_URL],
      blockExplorerUrls: [
        process.env.REACT_APP_POLYGON_TESTNET_BLOCK_EXPLORER_URL,
      ],
    },
  },

  // avalanche: {
  //   mainnet: {
  //     chainId: '0xa86a',
  //     chainName: 'Avalanche C-Chain',
  //     nativeCurrency: {
  //       name: 'Avalanche C-Chain',
  //       symbol: 'AVAX',
  //       decimals: 18,
  //     },
  //     rpcUrls: [process.env.REACT_APP_AVALANCHE_RPC_URL],
  //     blockExplorerUrls: [process.env.REACT_APP_AVALANCHE_EXPLORER_URL],
  //   },
  //   testnet: {
  //     chainId: '0xa869',
  //     chainName: 'Avalanche Fuji Testnet',
  //     nativeCurrency: {
  //       name: 'Avalanche Fuji Testnet',
  //       symbol: 'AVAX',
  //       decimals: 18,
  //     },
  //     rpcUrls: [process.env.REACT_APP_AVALANCHE_FUJI_RPC_URL],
  //     blockExplorerUrls: [process.env.REACT_APP_AVALANCHE_FUJI_EXPLORER_URL],
  //   },
  // },

  arbitrum: {
    mainnet: {
      chainId: '0xa4b1',
      chainName: 'Arbitrum',
      nativeCurrency: {
        name: 'Arbitrum',
        symbol: 'ETH',
        decimals: 18,
      },
      rpcUrls: [process.env.REACT_APP_ARBITRUM_RPC_URL],
      blockExplorerUrls: [process.env.REACT_APP_ARBITRUM_EXPLORER_URL],
    },
    testnet: {
      chainId: '0x66eee',
      chainName: 'Arbitrum Sepolia Testnet',
      nativeCurrency: {
        name: 'Arbitrum Sepolia Testnet',
        symbol: 'ETH',
        decimals: 18,
      },
      rpcUrls: [process.env.REACT_APP_ARBITRUM_SEPOLIA_RPC_URL],
      blockExplorerUrls: [process.env.REACT_APP_ARBITRUM_SEPOLIA_EXPLORER_URL],
    },
  },
};
